import { AppContext } from "App";
import BaseButton from "components/BaseButton";
import BaseTextField from "components/BaseTextField";
import { useFormik } from "formik";
import { host } from "index";
import React, { useContext } from "react";

export const DiscountDialog = () => {

    const {
        openModal,
        closeModal,
        discount,
        setDiscount,
    } = useContext(AppContext);

    const onSubmit = async (values) => {
        try {
            openModal()
            const res = await fetch(`${host}/discounts?discount_code=${values.discount_code}`, {
                method: 'get',
            })
            if (res.ok) {
                const body = await res.json();
                setDiscount({ ...body })
                var myModal = window.bootstrap.Modal.getInstance(document.getElementById('discount-dialog'));
                myModal.hide();
            } else {

            }
        } catch (e) {
            console.log(e);

        } finally {
            closeModal()
        }
    }

    const formik = useFormik({
        initialValues: {
            discount_code: ''
        },
        onSubmit
    })

    return (
        <div className="modal" id="discount-dialog" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-4">
                    <div className="modal-body">
                        <div className="">
                            <BaseTextField
                                name="discount_code"
                                label="Discount code"
                                form={formik}
                            />
                            <BaseButton onClick={formik.handleSubmit}>Apply</BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
