import "./card.css";
import { currency } from "../const";
import { useContext } from "react";
import { AppContext } from "App";


export function calcTotal(t, value){
    if(value === "Quarterly" && t != 0) t = (t - 2.5) * 4;

    else if(value == "Yearly" && t != 0) t = ((t - 2.5) * 12) - 10;
    return t;
}

const SubCard = ({ taxes, total, state, value, setState, }) => {

    const {
        discount
    } = useContext(AppContext);

    const onClick = () => setState(value);

    const isActive = value === state;

    let t = total(value);

    let tt = taxes(value);    
    
    if(discount != undefined){
         t -= discount.amount;
         if(tt) tt += discount.amount;
    }

    return (
        <div style={{
            cursor: "pointer",
        }}
            className="" onClick={onClick}>
            <div className="card features fea-primary rounded p-2 position-relative overflow-hidden border-1"
                style={{

                    backgroundColor: `${isActive ? '#FF6600' : ''}`,
                    color: `${isActive ? '#fff' : ''}`
                }}
            >
                <div className="card-statistic-3 p-4">
                    {/*<div className="card-icon card-icon-large"><i className="fas fa-shopping-cart"></i></div>*/}
                    <div className="mb-4">
                        <h5 className="card-title mb-0">{value}</h5>
                    </div>
                    <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                            <h2 className="d-flex align-items-center mb-0">
                                {currency} {t.toFixed(2)}
                            </h2>
                        </div>
                        <div className="col-4 text-right">
                            <span>{currency} {tt.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubCard;