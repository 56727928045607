import React from "react";
import {useDiscounts} from "./useDiscounts";
import {Link} from "react-router-dom";
import Table from "components/admin/Table";
import useCourse from "hooks/admin/useCourse";

export const Discounts = () => {

  const {
    discounts,
    setDiscounts
  } = useDiscounts();

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between">
          <h2>Discounts</h2>
          <div>
            <Link to={`/admin/discounts/new`} className="btn btn-primary login-btn-primary">New Discount</Link>
          </div>
        </div>
        <Table
          headers={[
            {title: "Code"},
            {title: "Amount"}

          ]}
          rows={discounts}
          resource="discounts"
          actions={false}
        />
      </div>

    </>
  );
}
