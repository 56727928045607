import AdminArticle from "views/admin/ShowArticle";
import Pages from 'views/admin/pages/Pages';
import PageForm from 'views/admin/pages/PageForm';
import AdminMasterClasses from 'views/admin/master_classes/MasterClasses';
import MasterClassForm from 'views/admin/master_classes/MasterClassForm';
import Users from 'views/admin/users/Users';
import Taxes from 'views/admin/taxes/Index';
import TaxForm from 'views/admin/taxes/TaxForm';
import AdminHome from "views/admin/Home";
import AdminCourse from "views/admin/Courses";
import AdminCourseForm from "views/admin/CourseForm";
import AdminTutors from "views/admin/Tutors";
import AdminTutorForm from "views/admin/TutorForm";
import AdminShowCourse from "views/admin/ShowCourse";
import AdminNewTest from "views/admin/NewTest";
import AdminNewVideo from "views/admin/videos/NewVideo";
import AdminShowTest from "views/admin/ShowTest";
import AdminCategories from "views/admin/Categories";
import AdminOrders from "views/admin/Orders";
import AdminVideo from "views/admin/Video";
import AdminCategoryForm from "views/admin/CategoryForm";
import AdminSections from "views/admin/Sections";
import AdminSectionForm from "views/admin/SectionForm";
import AdminArticleForm from "views/admin/ArticleForm";

import { stackBuilder as StackBuilder } from "lib/stackBuilder";
import { withSidebar } from "components/admin/sidebar/Sidebar";
import { Discounts } from "./discounts/Discounts";
import DiscountForm from "./discounts/form/DiscountForm";

const stackBuilder = (Component, path, auth, roles) =>  ({ Component: withSidebar(Component), path, auth, roles });

const AdminStack = [
    stackBuilder(AdminHome, "/admin", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminCourse, "/admin/courses", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminCourseForm, "/admin/courses/new", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminTutors, "/admin/tutors", true, ["Super Admin"]),
    stackBuilder(AdminTutorForm, "/admin/tutors/new", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminShowCourse, "/admin/courses/:id", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminNewTest, "/admin/sections/:id/tests/new", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminNewVideo, "/admin/sections/:id/videos/new", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminShowTest, "/admin/tests/:id", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminCategories, "/admin/categories", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminOrders, "/admin/orders", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminVideo, "/admin/videos/:id", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminCourseForm, "/admin/courses/:id/edit", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminTutorForm, "/admin/tutors/:id/edit", true, ["Super Admin"]),
    stackBuilder(AdminCategoryForm, "/admin/categories/new", true, ["Super Admin"]),
    stackBuilder(AdminCategoryForm, "/admin/categories/:id/edit", true, ["Super Admin"]),
    stackBuilder(AdminSections, "/admin/courses/:id/sections", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminSectionForm, "/admin/courses/:id/sections/new", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminSectionForm, "/admin/sections/:id/edit", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminArticleForm, "/admin/sections/:id/articles/new", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminArticle, "/admin/articles/:id", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminMasterClasses, "/admin/master_classes", true, ["Super Admin"]),
    stackBuilder(MasterClassForm, "/admin/master_classes/create", true, ["Super Admin"]),
    stackBuilder(Pages, "/admin/pages", true, ["Super Admin"]),
    stackBuilder(PageForm, "/admin/pages/:id/edit", true, ["Super Admin"]),
    stackBuilder(AdminArticleForm, "/admin/articles/:id/edit", true, ["Super Admin", "Tutor"]),
    stackBuilder(AdminNewVideo, "/admin/videos/:id/edit", true, ["Super Admin", "Tutor"]),
    stackBuilder(Users, "/admin/users", true, ["Super Admin"]),
    stackBuilder(MasterClassForm, "/admin/master_classes/:id/edit", true, ["Super Admin"]),
    stackBuilder(Taxes, "/admin/taxes", true, ["Super Admin"]),
    stackBuilder(TaxForm, "/admin/taxes/:id/edit", true, ["Super Admin"]),
    stackBuilder(TaxForm, "/admin/taxes/create", true, ["Super Admin"]),
    stackBuilder(Discounts, "/admin/discounts", true, ["Super Admin"]),
    stackBuilder(DiscountForm, "/admin/discounts/new", true, ["Super Admin"]),
]

export default AdminStack;