import React from "react";

export const useDiscounts = () => {

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [discounts, setDiscounts] = React.useState([]);


  const fetchDiscounts = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await window.authFetch(`admin/discounts`);
      if(res.ok){
        const body = await res.json();
        setDiscounts(body);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchDiscounts();
  }, []);


  return{
    fetchDiscounts,
    discounts,
    setDiscounts
  }

}
