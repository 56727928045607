import React from "react";
import useCourses from "hooks/admin/useCourses";
import Navbar from "components/admin/Navbar";
import { Link } from "react-router-dom";
import Table from "components/admin/Table";
import { AppContext } from "App";

const Courses = () => {

  const {
    isAdmin
  } = React.useContext(AppContext);

  const {
    courses,
    setCourses
  } = useCourses();

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between">
          <h2>Courses</h2>
          <div>
            <Link to="/admin/courses/new" className="btn btn-primary login-btn-primary">New Course</Link>
          </div>
        </div>
        <Table
          headers={[
            { title: "Title" },
            { title: "Code" },
            { title: "State" },
            { title: "Rating" },
            { title: "Duration" },
            { title: "Price" },
            { title: "Tutor" },
            { title: "Enrolled" },
          ]}
          rows={courses}
          resource="courses"
          actions={true}
          edit={false}
        />
      </div>
    </>
  );
}

export default Courses;
