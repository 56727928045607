import React from "react";
import { AppContext } from "App";
import { Course } from "views/Courses";
import Button from "components/BaseButton";
import Navbar from "components/Navbar";
import { Link } from "react-router-dom";
import TextField from "components/RawTextField";


import { useFormik } from 'formik';

import useCheckout from "hooks/useCheckout";

import * as yup from 'yup';
import SubCard, { calcTotal } from "components/SubCard";
import { DiscountDialog } from "./cart/DiscountDialog";

const validationSchema = yup.object({
  card_number: yup
    .string()
    .required(),
  cvc: yup
    .string()
    .max(3)
    .required(),
  expiry: yup
    .string()
    .required(),
});

const Cart = () => {

  const {
    cart,
    count,
    total,
    removeItem,
    currentUser,
    taxes,
    discount,
    setDiscount,
  } = React.useContext(AppContext);

  const {
    onCheckout: onSubmit,
    setState,
    state,
    asyncErrors
  } = useCheckout();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onApplyDiscount = () => {
    var myModal = new window.bootstrap.Modal(document.getElementById('discount-dialog'));
    myModal.show();
  }

  const onRemoveDiscount = () => {
    setDiscount()
  }

  const items = count();

  return (
    <>
      <Navbar />
      <section className="bg-profile d-table w-100 bg-primary" style={{ "background": "url('images/account/bg.png') center center" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className=" public-profile" style={{ "zIndex": 1 }}>
                <div className="card-body">
                  <div className="row align-items-center">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive bg-white shadow">
                <table className="table table-center table-padding mb-0">
                  <thead>
                    <tr>
                      <th className="border-bottom py-3" style={{ minWidth: "20px" }}></th>
                      <th className="border-bottom py-3" style={{ minWidth: "300px" }}>Course</th>
                      <th className="border-bottom text-center py-3" style={{ minWidth: "160px" }}>Price</th>
                      <th className="border-bottom text-center py-3" style={{ minWidth: "160px" }}>Subtotal</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      cart.map((cartItem, i) => {

                        let course = cartItem.cartable || { price: 0, subtotal: 0 };

                        let onRemoveItem = () => removeItem(course, i);

                        return (
                          <tr className="shop-list" key={i}>
                            <td className="h6"><a className="text-danger" onClick={onRemoveItem}>X</a></td>
                            <td>
                              <div className="d-flex align-items-center">
                                <img src={course.image} className="img-fluid avatar avatar-small rounded shadow" style={{ height: "auto" }} alt="" />
                                <h6 className="mb-0 ms-3">{course.title}</h6>
                              </div>
                            </td>
                            <td className="text-center">$ {cartItem.subtotal ? calcTotal(cartItem.subtotal, state) : 0}</td>
                            <td className="text-center">$ {course.price ? calcTotal(course.price, state) : 0}</td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row mt-5 pt-4">
            <div className="col-lg-4 mt-3 mt-md-0">
              <SubCard
                total={total}
                taxes={taxes}
                state={state}
                setState={setState}
                value="Monthly"
              />

            </div>
            <div className="col-lg-4 mt-3 mt-md-0">
              <SubCard
                total={total}
                taxes={taxes}
                state={state}
                setState={setState}
                value="Quarterly"
              />
            </div>
            <div className="col-lg-4 mt-3 mt-md-0">
              <SubCard
                total={total}
                taxes={taxes}
                state={state}
                setState={setState}
                value="Yearly"
              />

            </div>
            <div className="mt-4 pt-2 text-end d-flex justify-items-end">
              {
                !discount ?
                  items > 0 &&
                  <button className="btn btn-outline-primary me-2" onClick={onApplyDiscount}>Apply discount</button>
                  : <button className="btn btn-outline-primary me-2" onClick={onRemoveDiscount}>Discount applied</button>

              }
              {
                currentUser ?
                  <a className="btn btn-primary" onClick={onSubmit}>Proceed to checkout</a>
                  :
                  <Link to={`/login?redirect=${window.location.pathname}`} className="btn btn-primary">Log In to continue</Link>
              }
            </div>
          </div>
        </div>
      </section>
      <DiscountDialog />
    </>
  )
}

export default Cart;
