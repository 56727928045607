import React from "react";
import Navbar from "components/admin/Navbar";
import TextField from "components/BaseTextField";
import useDiscountForm from "./useDiscountForm";
import { Formik } from 'formik';
import * as Yup from "yup";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import handleSetImage from "lib/handleSetImage";
import { AppContext } from "App";

const DiscountFormSchema = Yup.object().shape({
  amount: Yup.number()
    .required(),
});

const DiscountForm = () => {

  const {
    currentUser
  } = React.useContext(AppContext);

  const {
    asyncErrors,
    onSubmit,
    isSubmitting,
    categories,
    tutors,
    isEditing,
    crop,
    setCrop,
    image,
    setImage,
    src,
    setSrc,
    discount,
    isFetching
  } = useDiscountForm();

  const onSetImage = ({ target }) => handleSetImage({ target, setSrc });

  if (isFetching) return null;

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-8">
            <div className="card login-page bg-white shadow rounded border-0">
              <div className="card-body">
                <h4 className="card-title">{isEditing ? "Edit Discount" : "New Discount"}</h4>
                <Formik
                  initialValues={discount}
                  validationSchema={DiscountFormSchema}
                  onSubmit={onSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                  className="login-form mt-4"
                >
                  {
                    ({ handleSubmit, ...form }) => {

                      return <form onSubmit={handleSubmit}>
                        <div className="row">


                          <TextField
                            name="amount"
                            form={form}
                            asyncErrors={asyncErrors}
                            label="Amount"
                          />

                          <div className="col-lg-12 mb-0">
                            <div className="d-grid">
                              <button
                                type="submit"
                                className="btn btn-primary"
                              >
                                {
                                  isEditing ?
                                    "Update Discount" :
                                    "Add Discount"
                                }
                              </button>
                            </div>
                          </div>

                        </div>
                      </form>
                    }
                  }
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DiscountForm;
